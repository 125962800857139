<template>
  <div class="">
    <div class="flex justify-center flex-1">
      <svg
        class="w-16 h-16 text-custom-green animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        v-if="isLoading"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
    <div v-if="!isLoading" class="flex flex-col gap-10 md:flex-row">
      <div class="w-full p-6 bg-white rounded shadow md:w-1/3 lg:w-1/4">
        <div class="flex flex-col items-center justify-center">
          <template v-if="profile?.photo_url">
            <img
              :src="profile?.photo_url"
              class="object-cover object-center w-40 h-40 rounded-full"
            />
          </template>
          <img
            v-else
            src="http://placehold.it/200?text=HuskyTask"
            class="rounded-full"
          />

          <div class="flex flex-col items-center w-full my-4">
            <h3 class="text-2xl font-bold capitalize">
              {{ profile?.first_name }} {{ profile?.last_name }}
            </h3>
            <p>{{ profile?.bio }}</p>
          </div>
          <div
            class="
              flex
              justify-center
              flex-1
              w-full
              p-4
              mb-4
              bg-gray-100
              rounded-sm
            "
          >
            <p class="text-xl font-bold text-gray-800 uppercase">About</p>
          </div>
          <div class="flex flex-col w-full my-4 rounded-sm">
            <p class="text-xl font-bold text-gray-800">Number</p>
            <p class="mb-4">{{ profile?.phone_number }}</p>
            <p class="text-xl font-bold text-gray-800">Address</p>
            <p class="">
              {{ profile?.address_line_1 }}, {{ profile?.address_line_1 }},
              {{ profile?.city }}, {{ profile?.province }} -
              <span class="uppercase"> {{ profile?.postal_code }}</span>
            </p>
            <button
              @click="logout"
              type="button"
              class="
                px-6
                py-2.5
                mt-10
                mb-2
                text-xl
                font-semibold
                tracking-wide
                text-white
                uppercase
                rounded-sm
                shadow
                bg-custom-green-dark
              "
            >
              Logout
            </button>
          </div>
        </div>
      </div>
      <div class="w-full p-6 bg-white rounded shadow md:w-2/3 lg:w-3/4">
        <h3 class="mb-5 text-2xl font-bold uppercase">Edit Profile</h3>
        <ProfileForm @profileUpdate="profileUpdate" />
      </div>
    </div>
  </div>
</template>
<script>
import ProfileForm from '@/components/ProfileForm.vue'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  components: { ProfileForm },

  setup () {
    const store = useStore()
    const router = useRouter()
    const profile = ref(null)
    const servicesList = ref([])
    const isLoading = ref(true)
    // const mapServices = (service) => {
    //   return service
    // }
    onMounted(async () => {
      await store.dispatch('data/getProfile')
      await store.dispatch('data/getServices')
      isLoading.value = false
      profile.value = await store.state.data.profile
      const servicesData = await store.state.data.services
      for (const key in servicesData) {
        if (Object.hasOwnProperty.call(servicesData, key)) {
          const categories = servicesData[key]
          for (const key in categories) {
            if (Object.hasOwnProperty.call(categories, key)) {
              const category = categories[key]
              if (
                profile.value !== undefined &&
                profile.value.services !== undefined
              ) {
                console.log(profile.value)
                if (profile.value.services.includes(category.id)) {
                  servicesList.value.push(category)
                }
              }
            }
          }
        }
      }
    })
    const logout = async () => {
      await store.dispatch('auth/logout').then(
        () => {
          router.push({
            name: 'Login'
          })
        },
        (error) => {
          console.error(error)
        }
      )
    }

    const profileUpdate = async () => {
      console.log('profile update called')
      profile.value = await store.state.data.profile
    }

    return { profile, isLoading, servicesList, logout, profileUpdate }
  }
}
</script>
