<template>
  <div
    id="talkjs-container-inbox"
    style="width: 100%; height: 500px"
  >
    <i>Loading chat...</i>
  </div>

</template>
<script>
import Talk from 'talkjs'
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  setup (props) {
    const store = useStore()
    const currentUser = ref({})
    onMounted(async () => {
      currentUser.value = await store.getters['auth/getUser'] // ("data/getUserProfile");
      // console.log('20 mounted called again')
      Talk.ready.then(function () {
        // console.log('22 mounted called again', currentUser.value)

        const userProfile = new Talk.User({
          id: currentUser.value.id,
          name: currentUser.value.first_name ? currentUser.value.first_name : 'name',
          email: currentUser.value.email ? currentUser.value.email + '@huskytask.com' : 'email@email.com',
          photoUrl: currentUser.value.photo_url
            ? currentUser.value.photo_url
            : 'https://picsum.photos/200',
          welcomeMessage: 'Hello',
          role: 'default'
        })
        // console.log('mounted called again')
        window.talkSession = new Talk.Session({
          appId: 'tvCx0vbU',
          me: userProfile
        })

        var inbox = window.talkSession.createInbox()
        // inbox.select(conversation);

        inbox.mount(document.getElementById('talkjs-container-inbox'))
      })
    })

    return {}
  }

}
</script>
