<template>
<div class="px-1 py-2 bg-white shadow sm:px-6 sm:py-8">
     <ChatBox/>
</div>
</template>

<script>
import ChatBox from '../components/ChatBox.vue'
export default {
  components: { ChatBox }
}
// tvCx0vbU
</script>
